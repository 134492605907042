import React from "react";

function Teamsection() {
  return (
    <div className="container mx-auto 2xl:py-[120px] xl:py-[120px] lg:py-[120px] md:py-[60px] py-[40px] xl:px-0 lg:px-0 px-[20px] ">
      <div className="text-center">
        <span className="text-center relative">
          <span className="2xl:text-[40px] xl:text-[40px] lg:text-[40px] md:text-[36px] text-[32px] font-semibold text-center ">
            Team members
          </span>
          <span className="absolute right-0 ">
            <img src="../assets/needs-vector.png"></img>
          </span>
        </span>
      </div>
      <div className="grid 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-2 md:grid-cols-2  mt-[50px] 2xl:gap-0 xl:gap-11 lg:gap-11">
        <div className="text-center w-[320px] 2xl:mb-0 xl:mb-0 lg:mb-0 mb-[20px] mx-auto">
          <div className="2xl:w-[320px] xl:w-[250px] lg:w-[250px] md:w-[320px] w-[100%] mx-auto object-cover text-center">
            <img src="../assets/image (12).png"></img>
          </div>
          <p className="2xl:mt-[30px] xl:mt-[30px] lg:mt-[30px] md:mt-[15px] mt-[10px] text-[#0F0F0F] text-[24px] font-medium">
            Nicholas
          </p>
          <p className="2xl:mt-[18px] xl:mt-[18px] md:mt-[15px] mt-[10px] text-[#363636] text-[24px] font-normal">
            UI UX DESIGNER
          </p>
        </div>
        <div className="text-center w-[320px] 2xl:mb-0 xl:mb-0 lg:mb-0 mb-[20px] mx-auto">
          <div className="2xl:w-[320px] xl:w-[250px] lg:w-[250px] md:w-[320px] w-[100%] mx-auto object-cover text-center">
            <img src="../assets/image (9).png"></img>
          </div>
          <p className="2xl:mt-[30px] xl:mt-[30px] lg:mt-[30px] md:mt-[15px] mt-[10px] text-[#0F0F0F] text-[24px] font-medium">
            kenneth Brian
          </p>
          <p className="2xl:mt-[18px] xl:mt-[18px] md:mt-[15px] mt-[10px] text-[#363636] text-[24px] font-normal">
            Digital Marketing
          </p>
        </div>
        <div className="text-center w-[320px] 2xl:mb-0 xl:mb-0 lg:mb-0 mb-[20px] mx-auto">
          <div className="2xl:w-[320px] xl:w-[250px] lg:w-[250px] md:w-[320px] w-[100%] mx-auto object-cover text-center">
            <img src="../assets/image (10).png"></img>
          </div>
          <p className="2xl:mt-[30px] xl:mt-[30px] lg:mt-[30px] md:mt-[15px] mt-[10px] text-[#0F0F0F] text-[24px] font-medium">
            Fredricks
          </p>
          <p className="2xl:mt-[18px] xl:mt-[18px] md:mt-[15px] mt-[10px] text-[#363636] text-[24px] font-normal">
            Software Engineer
          </p>
        </div>
        <div className="text-center w-[320px] 2xl:mb-0 xl:mb-0 lg:mb-0 mb-[20px] mx-auto">
          <div className="2xl:w-[320px] xl:w-[250px] lg:w-[250px] md:w-[320px] w-[100%] mx-auto object-cover text-center">
            <img src="../assets/image (11).png"></img>
          </div>
          <p className="2xl:mt-[30px] xl:mt-[30px] lg:mt-[30px] md:mt-[15px] mt-[10px] text-[#0F0F0F] text-[24px] font-medium">
            Angela Brenda
          </p>
          <p className="2xl:mt-[18px] xl:mt-[18px] md:mt-[15px] mt-[10px] text-[#363636] text-[24px] font-normal">
            Sr. Project Manager
          </p>
        </div>
      </div>
    </div>
  );
}

export default Teamsection;
