import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiConnector } from "../../networking/ApiConnector";
import { designationEndPoints } from "../../networking/Endpoints";

// Define an async thunk
export const fetchDesignation = createAsyncThunk(
  "fetchDesignation",
  async ({ token, currentPage, itemsPerPage }) => {
    let queryParams = {};
    if (currentPage && itemsPerPage) {
      queryParams.page_number = currentPage;
      queryParams.page_size = itemsPerPage;
    }
    const response = await apiConnector(
      "GET",
      designationEndPoints?.DESIGNATION_API,
      null,
      {
        Authorization: `Bearer ${token}`,
      },
      queryParams
    );

    return response.data;
  }
);

// Create a slice
const designationSlice = createSlice({
  name: "designation",
  initialState: {
    designationData: null,
    designationLoading: false,
    designationError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDesignation.pending, (state) => {
        state.designationLoading = true;
        state.designationData = null;
        state.designationError = null;
      })
      .addCase(fetchDesignation.fulfilled, (state, action) => {
        state.designationData = action.payload;
        state.designationLoading = false;
        state.designationError = null;
      })
      .addCase(fetchDesignation.rejected, (state, action) => {
        state.designationError = action.error.message;
        state.designationLoading = false;
        state.designationData = null;
      });
  },
});

export default designationSlice.reducer;
