import React, { useEffect, useRef, useState } from "react";
import {
  CustomActionDropDown,
  CustomAssignees,
  CustomButton,
  CustomDrawer,
  CustomInput,
  CustomModal,
  CustomSelect,
  CustomStatusButton,
  CustomStatusDropdown,
  NoDataFound,
  Pagination,
  PaginationText,
  TableHeader,
} from "../common";
import Skeleton from "react-loading-skeleton";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import useOnClickOutside from "../../helper/onClickOutside";
import { IoMdArrowDropdown, IoMdSearch } from "react-icons/io";
import {
  MdKeyboardArrowDown,
  MdOutlineEdit,
  MdOutlineFilterAlt,
} from "react-icons/md";
import { FaPlusCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import {
  dateFormatter,
  getDateDifferenceStatus,
} from "../../helper/formatDate";
import {
  priorityOptions,
  Roles,
  Status,
  statusOptions,
} from "../../constant/Constant";
import { setLoading } from "../../redux/slice/authSlice";
import { apiConnector } from "../../networking/ApiConnector";
import { taskEndPoints } from "../../networking/Endpoints";
import { toast } from "react-toastify";
import { IoSwapHorizontal } from "react-icons/io5";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { CheckStatus } from "../../helper/CheckStatus";
import { GrView } from "react-icons/gr";
import { BsDiagram2 } from "react-icons/bs";
import { setActiveTabInTask } from "../../redux/slice/taskSlice";
import {
  getAllProject,
  getAllProjectMilestone,
  setActiveTabInProject,
} from "../../redux/slice/projectSlice";
import { fetchAllEmployees } from "../../redux/slice/employeeSlice";
const baseUrl = process.env.REACT_APP_BASE_URL;
const statusUpdateFormValidation = yup.object().shape({
  hours_logged: yup
    .string()
    .matches(
      /^(?:\d{2}|\d{3}):([0-5]\d)$/,
      "Hours Logged must be in the format HH:MM"
    )
    .required("Hours Logged is required"),
  completed_on: yup.string().required("Completed_on is required"),
  completed_by: yup
    .array()
    .of(yup.string().required("Each member must be a valid string"))
    .required("Completed_by is required"),
});

const CommonTaskTable = ({
  currentPage = 1,
  itemsPerPage = 10,
  setCurrentPage = () => {},
  setItemsPerPage = () => {},
  tasks = [],
  setTasks,
  tasksTableData = [],
  setTasksTableData,
  taskCount = 0,
  defaultProject = "",
  fetchAllTasks,
}) => {
  const drawerRef = useRef(),
    statusRef = useRef(),
    navigate = useNavigate(),
    dispatch = useDispatch(),
    { token, user } = useSelector((state) => state.auth),
    { taskLoading } = useSelector((state) => state.task),
    [openFilterDrawer, setOpenFilterDrawer] = useState(false),
    [searchString, setSearchString] = useState(""),
    [taskId, setTaskId] = useState(""),
    [statusIndex, setStatusIndex] = useState(""),
    [openStatus, setOpenStatus] = useState(false),
    [statusModal, setStatusModal] = useState(false),
    [selectedStatus, setSelectedStatus] = useState(""),
    [projects, setProjects] = useState([]),
    [projectMileStones, setProjectMilestones] = useState([]),
    [employees, setEmployees] = useState([]),
    [taskAssignees, setTaskAssignees] = useState([]);

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(statusUpdateFormValidation) });

  const handleStatusChange = async (newStatus) => {
    setSelectedStatus(newStatus);
    setOpenStatus(false);
    setStatusModal(true);
  };
  const TableHeads = [
    "Task ID",
    "Name",
    "Start Date",
    "Due Date",
    "Assignees",
    "Assign By",
    "Status",
    "Actions",
  ];

  useOnClickOutside(drawerRef, () => setOpenFilterDrawer(false));
  useOnClickOutside(statusRef, () => setStatusIndex(null));

  const updateTaskStatusHandler = async (data) => {
    dispatch(setLoading(true));
    try {
      const task_response = await apiConnector(
        "PUT",
        `${taskEndPoints?.TASK_API}/${taskId}`,
        selectedStatus === "Completed" ? data : { status: selectedStatus },

        {
          Authorization: `Bearer ${token}`,
        }
      );
      toast.success(task_response?.data?.message);
      if (fetchAllTasks) {
        fetchAllTasks();
      }
      setStatusModal(false);
    } catch (error) {
      toast.error(error?.message);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const pageCount = Math.ceil(taskCount / itemsPerPage);
  const handlePageClick = (event) => {
    setCurrentPage(event?.selected + 1);
  };
  const onSubmitHandler = (data) => {
    const payload = {
      status: selectedStatus,
      hours_logged: data?.hours_logged ? data?.hours_logged : undefined,
      completed_on: data?.completed_on ? data?.completed_on : undefined,
      completed_by: data?.completed_by ? data?.completed_by : [],
    };
    updateTaskStatusHandler(payload);
  };
  const filterTask = (e) => {
    const val = e?.target?.value;
    setSearchString(e?.target?.value);
    if (val?.length > 0) {
      const filterTasks = tasks?.filter(
        (item) =>
          item?.title.toLowerCase().includes(val.toLowerCase()) ||
          item?.status.toLowerCase().includes(val.toLowerCase()) ||
          dateFormatter(item?.start_date)
            .toLowerCase()
            .includes(val.toLowerCase()) ||
          dateFormatter(item?.due_date)
            .toLowerCase()
            .includes(val.toLowerCase())
      );
      setTasksTableData(filterTasks);
    } else {
      setTasksTableData(tasks);
    }
  };
  const fetchProjects = async () => {
    try {
      const response = await dispatch(getAllProject({ token })).unwrap();
      if (response?.data) {
        setProjects(response?.data?.projects);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };
  const fetchEmployees = async () => {
    try {
      const response = await dispatch(fetchAllEmployees({ token })).unwrap();

      if (response?.data) {
        setEmployees(response?.data?.employees);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };
  const fetchMilestone = async () => {
    try {
      const projectId = defaultProject;
      const response = await dispatch(
        getAllProjectMilestone({
          token,
          projectId,
        })
      ).unwrap();

      if (response?.data) {
        setProjectMilestones(response?.data?.milestone);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    if (defaultProject && user?.roleId !== Roles?.employee) {
      fetchMilestone();
    }
  }, [defaultProject]);

  useEffect(() => {
    if (!defaultProject) {
      fetchProjects();
    }
    if (user?.roleId !== Roles?.employee) {
      fetchEmployees();
    }
  }, []);

  const projectOptions = projects?.map((project) => ({
    name: project?.project_name,
    value: project?._id,
  }));
  projectOptions.unshift({ name: "All", value: "" });
  const assign_to_options = employees?.map((item) => ({
    name: item?.user_name,
    value: item?._id,
    designation: item?.designation?.designation,
    avatar: item?.avatar
      ? `${baseUrl}/${item?.avatar}`
      : `https://api.dicebear.com/5.x/initials/svg?seed=${item?.user_name}`,
  }));
  assign_to_options.unshift({ name: "All", value: "" });
  const filteredEmployees = employees?.filter((item) => {
    return item?.roleId !== Roles?.employee;
  });
  const assign_by_options = filteredEmployees?.map((item) => ({
    name: item?.user_name,
    value: item?._id,
    designation: item?.designation?.designation,
    avatar: item?.avatar
      ? `${baseUrl}/${item?.avatar}`
      : `https://api.dicebear.com/5.x/initials/svg?seed=${item?.user_name}`,
  }));
  assign_by_options.unshift({ name: "All", value: "" });
  const milestoneOptions = projectMileStones?.map((item) => ({
    name: item?.milestone_name,
    value: item?._id,
  }));
  const selectedProject = watch("project");
  const selectedMilestone = watch("milestone");
  const selectedAssignTo = watch("assign_to");
  const selectedAssignBy = watch("assign_by");
  const selectedFilteredStatus = watch("status");
  const selectedPriority = watch("priority");

  const filterTaskApiHandler = () => {
    const filters = {
      ...(selectedProject && { project: selectedProject }),
      ...(selectedMilestone && { milestone: selectedMilestone }),
      ...(selectedAssignTo && { assign_to: selectedAssignTo }),
      ...(selectedAssignBy && { assign_by: selectedAssignBy }),
      ...(selectedFilteredStatus && { status: selectedFilteredStatus }),
      ...(selectedPriority && { priority: selectedPriority }),
    };
    // Extract the values to pass only the defined ones
    const { project, milestone, assign_to, assign_by, status, priority } =
      filters;
    fetchAllTasks(project, milestone, assign_to, assign_by, status, priority);
  };
  return (
    <div className="w-full bg-custom-white rounded-md">
      <section className="flex sm:flex-row flex-col justify-between items-center p-4 py-2 rounded-md">
        <h1 className="font-semibold">Task List</h1>
        <div className="flex flex-col md:flex-row justify-center items-center gap-4">
          <div className="relative ">
            <IoMdSearch className="absolute z-10 top-3 left-1 text-slate-400" />
            <input
              name="searchString"
              label=""
              type="text"
              placeholder="Search"
              onChange={(e) => filterTask(e)}
              value={searchString}
              className="bg-custom-white px-6 py-2 font-semibold text-sm border outline-none rounded-md"
            />
          </div>
          <CustomButton
            title={
              <div className="flex justify-center items-center font-semibold">
                <MdOutlineFilterAlt size={20} />
                <span>Filters</span>
              </div>
            }
            onClick={() =>
              setOpenFilterDrawer((openFilterDrawer) => !openFilterDrawer)
            }
            buttonType="submit"
            classname="bg-custom-white text-slate-400 p-2 font-semibold text-sm rounded-md border"
          />
          {user?.roleId !== Roles?.employee && (
            <CustomButton
              title={
                <div className="flex justify-center items-center gap-2 ">
                  <FaPlusCircle />
                  <span>Add Task</span>
                </div>
              }
              buttonType="button"
              onClick={() =>
                navigate("/task/create", { state: { project: defaultProject } })
              }
              classname="bg-gradient-custom text-custom-white font-semibold text-sm px-5 py-2 rounded-md"
            />
          )}
        </div>
        {openFilterDrawer && (
          <CustomDrawer
            ref={drawerRef}
            open={openFilterDrawer}
            setOpen={setOpenFilterDrawer}
            filterName="Task Filter"
          >
            {!defaultProject ? (
              <div className="my-4">
                <CustomSelect
                  name="project"
                  label="Project"
                  inputType="text"
                  placeholder="Select..."
                  control={control}
                  options={projectOptions ?? []}
                />
              </div>
            ) : (
              user?.roleId !== Roles?.employee && (
                <div className="my-4">
                  <CustomSelect
                    name="milestone"
                    label="Milestone"
                    inputType="text"
                    placeholder="Select..."
                    control={control}
                    options={milestoneOptions ?? []}
                  />
                </div>
              )
            )}
            {user?.roleId !== Roles?.employee && (
              <div className="my-4">
                <CustomSelect
                  name="assign_to"
                  label="Assign To"
                  inputType="text"
                  placeholder="Select..."
                  control={control}
                  options={assign_to_options ?? []}
                />
              </div>
            )}
            {user?.roleId !== Roles?.employee && (
              <div className="my-4">
                <CustomSelect
                  name="assign_by"
                  label="Assign By"
                  inputType="text"
                  placeholder="Select..."
                  control={control}
                  options={assign_by_options ?? []}
                />
              </div>
            )}
            <div className="my-4">
              <CustomSelect
                name="status"
                label="Status"
                inputType="text"
                placeholder="Select..."
                control={control}
                options={statusOptions ?? []}
              />
            </div>
            <div className="my-4">
              <CustomSelect
                name="priority"
                label="Priority"
                inputType="text"
                placeholder="Select..."
                control={control}
                options={priorityOptions ?? []}
              />
            </div>
            <div className="w-full absolute bottom-0 right-0 p-4">
              <div className="w-full h-[1px] bg-slate-200 my-2" />
              <div className="flex items-center justify-between ">
                <CustomButton
                  title={"Clear"}
                  onClick={() => {
                    reset({
                      department: "",
                      designation: "",
                      status: "",
                    });
                    fetchAllTasks();
                    setOpenFilterDrawer(false);
                  }}
                  buttonType="submit"
                  classname={"border px-5 py-1 rounded-md"}
                />
                <CustomButton
                  title={"Apply"}
                  onClick={() => {
                    filterTaskApiHandler();
                  }}
                  buttonType="submit"
                  classname={
                    "border px-5 py-1 rounded-md bg-custom-status-completed text-custom-white"
                  }
                />
              </div>
            </div>
          </CustomDrawer>
        )}
      </section>
      <section className="w-full h-full">
        <table className="w-full border rounded-lg overflow-auto">
          <thead>
            <TableHeader TableHeads={TableHeads} />
          </thead>
          {taskLoading ? (
            <tbody>
              <tr>
                <td
                  colSpan={TableHeads.length}
                  className="text-center font-semibold text-sm p-3"
                >
                  <Skeleton
                    count={5}
                    width={"100%"}
                    height={"22px"}
                    className="my-2"
                  />
                </td>
              </tr>
            </tbody>
          ) : tasksTableData && tasksTableData?.length > 0 ? (
            <>
              <tbody>
                {tasksTableData?.map((item, index) => {
                  return (
                    <tr
                      key={item?._id}
                      className="w-full border-b hover:bg-slate-100"
                    >
                      <td className="px-2 text-sm">{item?.taskId}</td>
                      <td className="px-2 text-sm truncate">
                        <div
                          onClick={() => {
                            dispatch(setActiveTabInTask("task_details"));
                            navigate("/task/view", {
                              state: { id: item?._id },
                            });
                          }}
                          className="flex flex-col justify-start cursor-pointer text-sm"
                        >
                          <span className="text-custom-black font-medium flex items-center gap-2 line-clamp-1">
                            <h2>{item?.title}</h2>
                            <div className="border p-0.5 rounded-md flex items-center">
                              <span>
                                <BsDiagram2 size={16} />
                              </span>
                              <span
                                onClick={(e) => {
                                  e.stopPropagation();
                                  dispatch(setActiveTabInTask("sub_task"));
                                  navigate("/task/view", {
                                    state: { id: item?._id },
                                  });
                                }}
                                className="text-[13px]"
                              >
                                {item?.completed_subtask_count}/
                                {item?.subtask_count}
                              </span>
                            </div>
                          </span>
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              dispatch(setActiveTabInProject("overview"));
                              navigate("/project/view", {
                                state: { id: item?.project?._id },
                              });
                            }}
                            className="text-[#525252] text-sm underline"
                          >
                            {item?.project?.project_name}
                          </span>
                        </div>
                      </td>

                      <td className="px-2 text-sm">
                        {item?.start_date
                          ? dateFormatter(item?.start_date, {
                              format: "MMM DD, YYYY",
                              includeTimeForToday: false,
                            })
                          : "--"}
                      </td>
                      <td className="px-2 text-sm">
                        {item?.due_date ? (
                          <>
                            {dateFormatter(item?.due_date, {
                              format: "MMM DD, YYYY",
                              includeTimeForToday: false,
                            })}{" "}
                            (
                            {getDateDifferenceStatus(
                              item?.due_date,
                              item?.status === "Completed"
                                ? item?.completed_on
                                : null
                            )}
                            )
                          </>
                        ) : (
                          "--"
                        )}
                      </td>

                      <td className="px-2 text-sm">
                        {<CustomAssignees assignees={item?.assign_to} /> ??
                          "--"}
                      </td>
                      <td className="px-2 text-sm">
                        <div className="flex justify-start items-center gap-3">
                          {item?.assign_by?._id === user?._id
                            ? "You"
                            : item?.assign_by?.user_name}
                        </div>
                      </td>
                      <td
                        onClick={() => {
                          if (
                            user?.roleId === Roles?.admin ||
                            user?.roleId === Roles?.project_manager ||
                            (user?.roleId === Roles?.employee &&
                              item?.assign_to?.find(
                                (element) => element?._id === user?._id
                              ))
                          ) {
                            setStatusIndex(index);
                            setTaskId(item?._id);
                            setTaskAssignees(item?.assign_to);
                            setOpenStatus(!openStatus);
                          }
                        }}
                        className={`text-sm cursor-pointer`}
                      >
                        <div
                          className={`w-fit relative p-2 cursor-pointer rounded-lg flex justify-between items-center  gap-2  `}
                        >
                          <CustomStatusButton
                            selectedStatus={item?.status}
                            isDropdown={true}
                          />
                          {openStatus && statusIndex === index && (
                            <CustomStatusDropdown
                              statusRef={statusRef}
                              handleStatusChange={handleStatusChange}
                            />
                          )}
                        </div>
                        {statusModal && (
                          <CustomModal
                            isOpen={statusModal}
                            onClose={() => setStatusModal(false)}
                          >
                            {selectedStatus === "Completed" ? (
                              <form
                                noValidate
                                onSubmit={handleSubmit(onSubmitHandler)}
                                className="w-full"
                              >
                                <div className="my-2 sm:my-4 ">
                                  <CustomInput
                                    name="hours_logged"
                                    label="Hours Logged"
                                    inputType="text"
                                    placeholder="00:00"
                                    control={control}
                                    required={true}
                                    error={errors?.hours_logged?.message}
                                  />
                                </div>
                                <div className="my-2 sm:my-4 ">
                                  <CustomInput
                                    name="completed_on"
                                    label="Completed On"
                                    inputType="date"
                                    placeholder="Select..."
                                    control={control}
                                    required={true}
                                    error={errors?.completed_on?.message}
                                  />
                                </div>
                                <div className="my-2 sm:my-4 ">
                                  <CustomSelect
                                    name="completed_by"
                                    label="Completed By"
                                    inputType="text"
                                    placeholder="Select..."
                                    control={control}
                                    multiple={true}
                                    required={true}
                                    options={
                                      taskAssignees?.map((member) => ({
                                        name:
                                          member?._id === user?._id
                                            ? "You"
                                            : member?.user_name,
                                        value: member?._id,
                                        designation:
                                          member?.designation?.designation,
                                        avatar: member?.avatar
                                          ? `${baseUrl}/${member?.avatar}`
                                          : `https://api.dicebear.com/5.x/initials/svg?seed=${member?.user_name}`,
                                      })) ?? []
                                    }
                                    classname="bg-custom-white"
                                    error={errors?.completed_by?.message}
                                  />
                                </div>
                                <div className="flex justify-end items-center gap-5 mt-5">
                                  <CustomButton
                                    title={"Cancel"}
                                    onClick={() => setStatusModal(false)}
                                    buttonType={"button"}
                                    classname={
                                      "px-2 py-1 bg-custom-white border rounded-md "
                                    }
                                  />
                                  <CustomButton
                                    title={"Continue"}
                                    buttonType={"submit"}
                                    classname={
                                      "bg-gradient-custom text-custom-white px-2 py-1 rounded-md"
                                    }
                                  />
                                </div>
                              </form>
                            ) : (
                              <>
                                <div className="text-center text-custom-black">
                                  <h2 className="text-2xl font-bold my-4">
                                    Change Status
                                  </h2>
                                </div>
                                <div className="w-20 h-20 bg-[#C3E6FF] p-2 rounded-full flex justify-center items-center">
                                  <div className="bg-custom-blue rounded-full w-16 h-16 flex justify-center items-center">
                                    <IoSwapHorizontal
                                      size={32}
                                      className="text-custom-white"
                                    />
                                  </div>
                                </div>
                                <div className="text-center text-[#747474]">
                                  <h2 className="font-medium my-4">
                                    are you sure you want to change status?
                                  </h2>
                                </div>

                                <div className="flex justify-end items-center gap-5 mt-5">
                                  <CustomButton
                                    title={"Cancel"}
                                    onClick={() => setStatusModal(false)}
                                    buttonType={"button"}
                                    classname={
                                      "px-2 py-1 bg-custom-white border rounded-md "
                                    }
                                  />
                                  <CustomButton
                                    title={"Continue"}
                                    onClick={() => {
                                      updateTaskStatusHandler();
                                    }}
                                    buttonType={"button"}
                                    classname={
                                      "bg-gradient-custom text-custom-white px-2 py-1 rounded-md"
                                    }
                                  />
                                </div>
                              </>
                            )}
                          </CustomModal>
                        )}
                      </td>
                      {user?.roleId !== Roles?.employee && (
                        <td className="w-16 h-16 flex justify-center items-center gap-2 cursor-pointer">
                          <div
                            onClick={() => {
                              dispatch(setActiveTabInTask("task_details"));
                              navigate("/task/view", {
                                state: { id: item?._id },
                              });
                            }}
                            className="p-1 border border-green-600 rounded-md cursor-pointer"
                          >
                            <GrView className="text-green-600" />
                          </div>
                          <div
                            onClick={() =>
                              navigate("/task/create", {
                                state: { task: item },
                              })
                            }
                            className="p-1 border border-custom-blue rounded-md cursor-pointer"
                          >
                            <MdOutlineEdit className="text-custom-blue" />
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </>
          ) : (
            <tbody>
              <tr>
                <td
                  colSpan={TableHeads?.length}
                  className="text-center font-semibold text-sm p-4"
                >
                  <NoDataFound />
                </td>
              </tr>
            </tbody>
          )}
          <tfoot className="relative">
            <tr>
              <td className="text-slate-400 text-sm px-2 py-4" colSpan={6}>
                <PaginationText
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  dataCount={taskCount}
                  dataLength={tasks?.length}
                />
              </td>
              <td className="absolute right-2 top-1.5">
                <Pagination
                  handlePageClick={handlePageClick}
                  pageRangeDisplayed={itemsPerPage}
                  pageCount={pageCount}
                  name={"pageSize"}
                  onChange={(e) => setItemsPerPage(e?.target?.value)}
                />
              </td>
            </tr>
          </tfoot>
        </table>
      </section>
    </div>
  );
};

export default CommonTaskTable;
