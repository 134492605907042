import React, { useEffect, useRef, useState } from "react";
import ProjectCard from "./ProjectCard";
import {
  CustomButton,
  CustomDrawer,
  CustomSelect,
  NoDataFound,
  Pagination,
  PaginationText,
} from "../common";
import { MdOutlineFilterAlt } from "react-icons/md";
import { FaPlusCircle } from "react-icons/fa";
import { IoMdSearch } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import {
  getAllProject,
  setActiveTabInProject,
} from "../../redux/slice/projectSlice";
import { priorityOptions, Roles, statusOptions } from "../../constant/Constant";
import { useForm } from "react-hook-form";
import { fetchDepartment } from "../../redux/slice/departmentSlice";
import { fetchAllEmployees } from "../../redux/slice/employeeSlice";
import useOnClickOutside from "../../helper/onClickOutside";

const baseUrl = process.env.REACT_APP_BASE_URL;
const Project = () => {
  const navigate = useNavigate(),
    dispatch = useDispatch(),
    drawerRef = useRef(),
    [searchString, setSearchString] = useState(""),
    { token, user } = useSelector((state) => state.auth),
    { projectLoading } = useSelector((state) => state.project),
    [isEditMode, setIsEditMode] = useState(false),
    [openFilterDrawer, setOpenFilterDrawer] = useState(false),
    [departments, setDepartments] = useState([]),
    [employees, setEmployees] = useState([]),
    [projects, setProjects] = useState([]),
    [tableData, setTableData] = useState([]),
    [projectCount, setProjectCount] = useState(0),
    [itemsPerPage, setItemsPerPage] = useState(10),
    [currentPage, setCurrentPage] = useState(1);

  const { control, handleSubmit, reset, watch } = useForm();
  useOnClickOutside(drawerRef, () => setOpenFilterDrawer(false));
  const fetchProjects = async (
    department,
    members,
    projectStatus,
    priority
  ) => {
    try {
      const response = await dispatch(
        getAllProject({
          token,
          department,
          members,
          projectStatus,
          priority,
          currentPage,
          itemsPerPage,
        })
      ).unwrap();

      if (response?.data) {
        setProjects(response?.data?.projects);
        setTableData(response?.data?.projects);
        setProjectCount(response?.data?.project_count);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, [currentPage, itemsPerPage]);

  const pageCount = Math.ceil(projectCount / itemsPerPage);

  const handlePageClick = (event) => {
    setCurrentPage(event?.selected + 1);
    fetchProjects();
  };
  const filterProject = (e) => {
    const val = e?.target?.value;
    setSearchString(e?.target?.value);
    if (val?.length > 0) {
      const filteredProject = projects?.filter(
        (item) =>
          item?.project_name.toLowerCase().includes(val.toLowerCase()) ||
          item?.projectStatus.toLowerCase().includes(val.toLowerCase())
      );
      setTableData(filteredProject);
    } else {
      setTableData(projects);
    }
  };
  const fetchDepartments = async () => {
    try {
      const response = await dispatch(fetchDepartment({ token })).unwrap();

      if (response?.data) {
        setDepartments(response?.data?.departments);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };
  const fetchEmployees = async () => {
    try {
      const response = await dispatch(fetchAllEmployees({ token })).unwrap();

      if (response?.data) {
        setEmployees(response.data.employees);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };
  useEffect(() => {
    if (user?.roleId !== Roles?.employee) {
      fetchDepartments();
      fetchEmployees();
    }
  }, []);
  const departmentOptions = departments?.map((department) => ({
    name: department?.department_name,
    value: department?._id,
  }));
  departmentOptions.unshift({ name: "All", value: "" });
  const filteredEmployees = employees?.filter((item) => {
    return item?.roleId !== Roles?.admin && item?._id !== user?._id;
  });
  const employeeOptions = filteredEmployees?.map((item) => ({
    name: item?.user_name,
    value: item?._id,
    designation: item?.designation?.designation,
    avatar: item?.avatar
      ? `${baseUrl}/${item?.avatar}`
      : `https://api.dicebear.com/5.x/initials/svg?seed=${item?.user_name}`,
  }));
  employeeOptions.unshift({ name: "All", value: "" });
  const selectedDepartement = watch("department");
  const selectedMember = watch("project_member");
  const selectedStatus = watch("status");
  const selectedPriority = watch("priority");

  const filterProjectApiHandler = () => {
    const filters = {
      ...(selectedDepartement && { department: selectedDepartement }),
      ...(selectedMember && { members: selectedMember }),
      ...(selectedStatus && { projectStatus: selectedStatus }),
      ...(selectedPriority && { priority: selectedPriority }),
    };
    // Extract the values to pass only the defined ones
    const { department, members, projectStatus, priority } = filters;
    fetchProjects(department, members, projectStatus, priority);
  };
  return (
    <div className="w-full bg-custom-white rounded-md p-5 space-y-2">
      <section className="flex md:flex-row flex-col gap-2 justify-between bg-custom-white items-center p-2 rounded-md">
        <h1 className="font-semibold">Project List</h1>
        <div className="flex flex-col md:flex-row justify-center items-center gap-4">
          <div className="relative ">
            <IoMdSearch className="absolute z-10 top-3 left-1 text-slate-400" />
            <input
              name="searchString"
              label=""
              type="text"
              placeholder="Search"
              onChange={(e) => filterProject(e)}
              value={searchString}
              className="bg-custom-white px-6 py-2 text-sm font-semibold border outline-none rounded-md"
            />
          </div>
          <CustomButton
            title={
              <div className="flex justify-center items-center font-semibold">
                <MdOutlineFilterAlt size={20} />
                <span>Filters</span>
              </div>
            }
            onClick={() =>
              setOpenFilterDrawer((openFilterDrawer) => !openFilterDrawer)
            }
            buttonType="submit"
            classname="bg-custom-white text-slate-400 p-2 text-sm font-semibold rounded-md border"
          />
          {user?.roleId !== Roles?.employee && (
            <CustomButton
              title={
                <div className="flex justify-center items-center gap-2 ">
                  <FaPlusCircle />
                  <span>Add Project</span>
                </div>
              }
              buttonType="button"
              onClick={() => navigate("/project/create")}
              classname="bg-gradient-custom text-custom-white font-semibold text-sm px-5 py-2 rounded-md"
            />
          )}
        </div>
        {openFilterDrawer && (
          <CustomDrawer
            ref={drawerRef}
            open={openFilterDrawer}
            setOpen={setOpenFilterDrawer}
            filterName="Project Filter"
          >
            {user?.roleId !== Roles?.employee && (
              <div className="my-4">
                <CustomSelect
                  name="department"
                  label="Department"
                  inputType="text"
                  placeholder="Select..."
                  control={control}
                  options={departmentOptions ?? []}
                />
              </div>
            )}
            {user?.roleId !== Roles?.employee && (
              <div className="my-4">
                <CustomSelect
                  name="project_member"
                  label="Project Member"
                  inputType="text"
                  placeholder="Select..."
                  control={control}
                  options={employeeOptions ?? []}
                />
              </div>
            )}
            <div className="my-4">
              <CustomSelect
                name="status"
                label="Status"
                inputType="text"
                placeholder="Select..."
                control={control}
                options={statusOptions ?? []}
              />
            </div>
            <div className="my-4">
              <CustomSelect
                name="priority"
                label="Priority"
                inputType="text"
                placeholder="Select..."
                control={control}
                options={priorityOptions ?? []}
              />
            </div>
            <div className="w-full absolute bottom-0 right-0 p-4">
              <div className="w-full h-[1px] bg-slate-200 my-2" />
              <div className="flex items-center justify-between ">
                <CustomButton
                  title={"Clear"}
                  onClick={() => {
                    reset({
                      department: "",
                      designation: "",
                      status: "",
                    });
                    fetchProjects();
                    setOpenFilterDrawer(false);
                  }}
                  buttonType="submit"
                  classname={"border px-5 py-1 rounded-md"}
                />
                <CustomButton
                  title={"Apply"}
                  onClick={() => {
                    filterProjectApiHandler();
                  }}
                  buttonType="submit"
                  classname={
                    "border px-5 py-1 rounded-md bg-custom-status-completed text-custom-white"
                  }
                />
              </div>
            </div>
          </CustomDrawer>
        )}
      </section>
      {projectLoading ? (
        <div className="w-full grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-5">
          {[1, 2, 3, 4, 5, 6]?.map((index) => (
            <div
              key={index}
              className="w-full border border-[#E8F0F6] bg-custom-white p-4 rounded-lg shadow-[2px_3px_10px_6px_#257CBB0F] space-y-3 cursor-pointer flex flex-col"
            >
              <div className="flex justify-between items-center space-x-2">
                <div className="w-40">
                  <Skeleton width={"70"} />
                </div>
                <div className="w-[15px] h-[15px]">
                  <Skeleton circle width={"100%"} height="100%" />
                </div>
              </div>
              <div>
                <Skeleton count={3} />
              </div>
              <div className="flex space-x-2">
                <div className="w-[25px] h-[25px]">
                  <Skeleton circle width={"100%"} height="100%" />
                </div>
                <div className="w-[25px] h-[25px]">
                  <Skeleton circle width={"100%"} height="100%" />
                </div>
                <div className="w-[25px] h-[25px]">
                  <Skeleton circle width={"100%"} height="100%" />
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : tableData && tableData?.length > 0 ? (
        <section className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-5">
          {tableData?.map((project) => (
            <ProjectCard
              key={project?._id}
              _id={project?._id}
              project_name={project?.project_name}
              project_manager={project?.project_manager}
              department={project?.department}
              project_id={project?.project_id}
              project_description={project?.project_description}
              start_date={project?.project_start_date}
              end_date={project?.project_deadline}
              assignees={project?.members}
              status={project?.projectStatus}
              priority={project?.priority}
              progress={project?.project_progress}
              total_task={project?.total_task}
              completed_task={project?.completed_task}
              onClick={() => {
                dispatch(setActiveTabInProject("overview"));
                navigate("/project/view", { state: { id: project?._id } });
              }}
              setIsEditMode={setIsEditMode}
              fetchAllProjects={fetchProjects}
            />
          ))}
        </section>
      ) : (
        <p className="p-2 font-semibold text-sm">
          <NoDataFound />
        </p>
      )}
      <section className="flex sm:flex-row flex-col justify-between items-center text-slate-400 text-sm">
        <PaginationText
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          dataCount={projectCount}
          dataLength={projects?.length}
        />

        <Pagination
          handlePageClick={handlePageClick}
          pageRangeDisplayed={itemsPerPage}
          pageCount={pageCount}
          name={"pageSize"}
          onChange={(e) => setItemsPerPage(e?.target?.value)}
        />
      </section>
    </div>
  );
};

export default Project;
