import React from "react";
import { useSelector } from "react-redux";
import { Roles } from "../../constant/Constant";

const TableHeader = ({ TableHeads }) => {
  const { user } = useSelector((state) => state.auth);
  return (
    <tr className="text-sm sm:text-[13px] text-slate-600 bg-[#E3F0FA] font-medium">
      {TableHeads?.map((item, index) => {
        if (item === "Actions" && user?.roleId === Roles?.employee) {
          return;
        }
        return (
          <th key={index} className="text-left p-2">
            {item}
          </th>
        );
      })}
    </tr>
  );
};

export default TableHeader;
