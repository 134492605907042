import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiConnector } from "../../networking/ApiConnector";
import { departmentEndPoints } from "../../networking/Endpoints";

// Define an async thunk
export const fetchDepartment = createAsyncThunk(
  "fetchDepartment",
  async ({ token, currentPage, itemsPerPage }) => {
    let queryParams = {};
    if (currentPage && itemsPerPage) {
      queryParams.page_number = currentPage;
      queryParams.page_size = itemsPerPage;
    }
    const response = await apiConnector(
      "GET",
      departmentEndPoints.DEPARTMENT_API,
      null,
      {
        Authorization: `Bearer ${token}`,
      },
      queryParams
    );

    return response.data;
  }
);

// Create a slice
const departmentSlice = createSlice({
  name: "department",
  initialState: {
    departmentData: null,
    departmentLoading: false,
    departmentError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDepartment.pending, (state) => {
        state.departmentLoading = true;
        state.departmentData = null;
        state.departmentError = null;
      })
      .addCase(fetchDepartment.fulfilled, (state, action) => {
        state.departmentData = action.payload;
        state.departmentLoading = false;
        state.departmentError = null;
      })
      .addCase(fetchDepartment.rejected, (state, action) => {
        state.departmentError = action.error.message;
        state.departmentLoading = false;
        state.departmentData = null;
      });
  },
});

export default departmentSlice.reducer;
