import React, { useEffect, useRef, useState } from "react";
import LogoIcon from "../../assets/LogoIcon.png";
import { GoBell } from "react-icons/go";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logout from "./Logout";
import { CiMenuBurger } from "react-icons/ci";
import { CgProfile } from "react-icons/cg";
import { LuLogOut } from "react-icons/lu";
const baseUrl = process.env.REACT_APP_BASE_URL;
const Header = ({ showSidebar, setShowSidebar }) => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const ref = useRef();
  const [openDropdown, setOpenDropDown] = useState(false);
  const [logout, setLogout] = useState(false);
  const items = [
    {
      name: "Profile",
      id: 1,
      onClick: () => {
        navigate("/profile");
        setOpenDropDown(false);
      },
      icon: <CgProfile />,
    },
    {
      name: "Log Out",
      id: 2,
      onClick: () => {
        setOpenDropDown(false);
        setLogout((logout) => !logout);
      },
      icon: <LuLogOut />,
    },
  ];
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      setOpenDropDown(false);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [openDropdown]);
  return (
    <>
      <div className="w-full h-16 p-4 flex justify-between items-center md:gap-5 bg-custom-white shadow-md shadow-[#00000014]">
        <div
          onClick={() => setShowSidebar(!showSidebar)}
          className="cursor-pointer lg:hidden flex justify-center items-center gap-5"
        >
          <CiMenuBurger className="text-custom-blue bg-custom-light-blue font-bold w-8 h-8 rounded-full border p-2" />
          <h1 className="text-2xl text-custom-blue font-bold">PMS</h1>
        </div>
        <div className="lg:flex justify-center items-center gap-40 cursor-pointer hidden">
          <div className="flex justify-center items-center gap-4">
            <div className="bg-custom-blue  rounded-full  md:w-10 md:h-10 p-2 flex justify-center items-center">
              <img src={LogoIcon} alt="Logo" loading="lazy" />
            </div>
            <h1 className="text-2xl text-custom-blue font-bold">PMS</h1>
          </div>
          {/* <div className="hidden md:block">
            <span className="text-2xl text-custom-black font-semibold">
              Home
            </span>
          </div> */}
        </div>
        <div className="flex justify-center items-center gap-3 cursor-pointer mr-3">
          <div className="relative w-8 h-8  bg-[#E7F1F8] rounded-full md:flex justify-center items-center hidden">
            <div className="absolute top-1 right-2 w-1.5 h-1.5 rounded-full bg-pink-900" />
            <GoBell size={20} />
          </div>
          <div
            className="flex justify-center items-center md:gap-1 transition-all duration-300 ease-linear "
            onClick={() => setOpenDropDown((openDropdown) => !openDropdown)}
          >
            <div className="w-8 h-8">
              <img
                src={
                  user?.avatar
                    ? `${baseUrl}/${user?.avatar}`
                    : `https://api.dicebear.com/5.x/initials/svg?seed=${user?.user_name}`
                }
                alt={user?.user_name[0]}
                loading="lazy"
                className="w-full h-full rounded-full"
              />
            </div>
            <div className="hidden md:block">
              {openDropdown ? <FaAngleUp /> : <FaAngleDown />}
            </div>
          </div>
        </div>
      </div>
      {openDropdown && (
        <div className="relative" ref={ref}>
          <div className="absolute right-6 z-50 -top-[18px] bg-custom-white w-5 h-5 rotate-45 border-t border-l" />
          <div className="absolute right-2 -top-2 p-4 rounded-md w-40 z-30 bg-custom-white flex flex-col justify-center items-start gap-3 border ">
            {items?.map((item) => (
              <button
                onClick={item?.onClick}
                key={item?.id}
                className="w-full hover:bg-slate-300 transition-all duration-300 ease-linear p-1 rounded-md cursor-pointer flex items-center gap-4"
              >
                <div className="w-6 h-6 rounded-full bg-[#C8E6FC] text-custom-blue p-1 flex justify-center items-center">
                  {item?.icon}
                </div>
                <span className="text-custom-blue">{item?.name}</span>
              </button>
            ))}
          </div>
        </div>
      )}
      {logout && <Logout logout={logout} setLogout={setLogout} />}
    </>
  );
};

export default Header;
