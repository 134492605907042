import React, { useEffect, useState } from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { TableHeader } from "../common";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { dateFormatter, groupByDate } from "../../helper/formatDate";
import { IoArrowForwardSharp } from "react-icons/io5";
import { setLoading } from "../../redux/slice/authSlice";
import { apiConnector } from "../../networking/ApiConnector";
import { taskEndPoints } from "../../networking/Endpoints";
import { toast } from "react-toastify";
import { CheckStatus } from "../../helper/CheckStatus";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const keyName = (item) => {
  switch (item) {
    case "company":
      return "Company";
    case "taskId":
      return "Task ID";
    case "project":
      return "Project";
    case "title":
      return "Title";
    case "description":
      return "Description";
    case "status":
      return "Status";
    case "priority":
      return "Priority";
    case "assign_to":
      return "Assign To";
    case "estimated_time":
      return "Estimated Time";
    case "hours_logged":
      return "Hours Logged";
    case "completed_on":
      return "Completed On";
    case "completed_by":
      return "Completed By";
    case "assign_by":
      return "Assign By";
    case "start_date":
      return "Start Date";
    case "due_date":
      return "Due Date";
    case "milestone":
      return "Milestone";
    case "subtask":
      return "Subtask";
    // case "comments":
    //   return "Comments";
    default:
      return "";
  }
};

const TaskHistory = ({ taskId }) => {
  const dispatch = useDispatch(),
    { token, loading, user } = useSelector((state) => state.auth),
    [openLogIndex, setOpenLogIndex] = useState(0),
    [openLog, setOpenLog] = useState(false),
    TableHeads = ["Name", "Change Type", "Changed by", "Date", "Time"];

  const [tableData, setTableData] = useState([]);

  const fetchLogs = async () => {
    dispatch(setLoading(true));
    try {
      const logs_response = await apiConnector(
        "GET",
        `${taskEndPoints?.LOGS_API}/${taskId}`,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setTableData(logs_response?.data?.changeLogs);
    } catch (error) {
      toast.error(error?.message);
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    fetchLogs();
  }, [taskId]);
  const groupedLogs = groupByDate(tableData ?? []);

  return (
    <div className="w-full md:h-[82vh] p-4 overflow-auto">
      {Object?.entries(groupedLogs)?.length > 0 ? (
        Object?.entries(groupedLogs)?.map(([date, items], index) => (
          <div
            key={date + index}
            onClick={() => {
              if (index === openLogIndex) {
                setOpenLog((openLog) => !openLog);
              } else {
                // Open the new log
                setOpenLogIndex(index);
              }
            }}
            className="w-full p-2 border rounded-md cursor-pointer my-4"
          >
            <div className="flex justify-between items-center">
              <span className="font-semibold">{date}</span>
              <span>
                <MdOutlineKeyboardArrowDown />
              </span>
            </div>
            {openLog && openLogIndex === index && (
              <section className="w-full">
                <table className="w-full border rounded-lg bg-custom-white overflow-auto">
                  <thead>
                    <TableHeader TableHeads={TableHeads} />
                  </thead>
                  {loading ? (
                    <tbody>
                      <tr>
                        <td
                          colSpan={TableHeads.length}
                          className="text-center font-semibold text-sm p-3"
                        >
                          <Skeleton
                            count={5}
                            width={"100%"}
                            height={"22px"}
                            className="my-2"
                          />
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {items?.map((item) => {
                        return (
                          <tr
                            key={item?._id}
                            onClick={(e) => e?.stopPropagation()}
                            className="w-full border-b hover:bg-slate-100"
                          >
                            <td className="p-3">
                              {item?.changes?.map((change, index) => (
                                <div key={index}>
                                  <span className="text-base font-semibold">
                                    {keyName(change?.field_name)}
                                  </span>
                                  {change?.field_name === "assign_to" ||
                                  change?.field_name === "completed_by" ? (
                                    <div className="flex items-center gap-2 py-2 text-sm">
                                      {change?.previous_value?.length
                                        ? change?.previous_value?.map(
                                            (item) => (
                                              <div
                                                key={item?._id}
                                                className="flex justify-start items-center gap-4 cursor-pointer"
                                              >
                                                <img
                                                  src={
                                                    item?.avatar
                                                      ? `${BASE_URL}/${item?.avatar}`
                                                      : `https://api.dicebear.com/5.x/initials/svg?seed=${item?.user_name}`
                                                  }
                                                  alt={item?.user_name[0]}
                                                  className="w-7 h-7 rounded-full bg-slate-300 p-0.5"
                                                />
                                                <span>{item?.user_name}</span>
                                              </div>
                                            )
                                          )
                                        : "--"}
                                      <IoArrowForwardSharp />
                                      {change?.updated_value?.map((item) => (
                                        <div
                                          key={item?._id}
                                          className="flex justify-start items-center gap-4 cursor-pointer"
                                        >
                                          <img
                                            src={
                                              item?.avatar
                                                ? `${BASE_URL}/${item?.avatar}`
                                                : `https://api.dicebear.com/5.x/initials/svg?seed=${item?.user_name}`
                                            }
                                            alt={item?.user_name[0]}
                                            className="w-7 h-7 rounded-full bg-slate-300 p-0.5"
                                          />
                                          <span>{item?.user_name}</span>
                                        </div>
                                      ))}
                                    </div>
                                  ) : change?.field_name === "milestone" ? (
                                    <div
                                      className={`flex items-center gap-2 py-2 text-sm`}
                                    >
                                      <span
                                        className={`${
                                          item?.field_name === "status"
                                            ? CheckStatus(
                                                change?.previous_value
                                              )
                                            : ""
                                        }`}
                                      >
                                        {change?.previous_value
                                          ? change?.previous_value
                                              ?.milestone_name
                                          : "--"}
                                      </span>
                                      <IoArrowForwardSharp />
                                      <span
                                        className={`${
                                          item?.field_name === "status"
                                            ? CheckStatus(change?.updated_value)
                                            : ""
                                        }`}
                                      >
                                        {change?.updated_value
                                          ? change?.updated_value
                                              ?.milestone_name
                                          : "--"}
                                      </span>
                                    </div>
                                  ) : (
                                    <div
                                      className={`flex items-center gap-2 py-2 text-sm`}
                                    >
                                      <span
                                        className={`${
                                          item?.field_name === "status"
                                            ? CheckStatus(
                                                change?.previous_value
                                              )
                                            : ""
                                        }`}
                                      >
                                        {change?.previous_value
                                          ? change?.previous_value
                                          : "--"}
                                      </span>
                                      <IoArrowForwardSharp />
                                      <span
                                        className={`${
                                          item?.field_name === "status"
                                            ? CheckStatus(change?.updated_value)
                                            : ""
                                        }`}
                                      >
                                        {change?.updated_value
                                          ? change?.updated_value
                                          : "--"}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              ))}
                            </td>
                            <td className="text-sm text-slate-950">
                              {item?.change_type ?? "--"}
                            </td>
                            <td className="p-3 text-sm">
                              <div className="flex justify-start items-center gap-4 cursor-pointer">
                                <img
                                  src={
                                    item?.change_by?.avatar
                                      ? `${BASE_URL}/${item?.change_by?.avatar}`
                                      : `https://api.dicebear.com/5.x/initials/svg?seed=${item?.change_by?.user_name}`
                                  }
                                  alt={item?.change_by?.user_name[0]}
                                  className="w-7 h-7 rounded-full bg-slate-300 p-0.5"
                                />
                                <span>{item?.change_by?.user_name}</span>
                              </div>
                            </td>
                            <td className="p-3 text-sm">
                              {dateFormatter(item?.createdAt, {
                                format: "MMM DD, YYYY",
                                includeTimeForToday: false,
                              }) ?? "--"}
                            </td>

                            <td className="p-3 text-sm">
                              {dateFormatter(item?.createdAt, {
                                format: "hh:mm A",
                                includeTimeForToday: true,
                              }) ?? "--"}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </table>
              </section>
            )}
          </div>
        ))
      ) : (
        <div>No Data Found</div>
      )}
    </div>
  );
};

export default TaskHistory;
