import React, { useEffect, useState } from "react";
import { CustomChart, CustomStatusButton } from "../common";
import { ApiError } from "../../helper/ApiError";
import { apiConnector } from "../../networking/ApiConnector";
import {
  milestoneEndPoints,
  projectEndPoints,
} from "../../networking/Endpoints";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Heading } from ".";
import {
  dateFormatter,
  getDateDifferenceStatus,
} from "../../helper/formatDate";
import Skeleton from "react-loading-skeleton";
import { chartColors } from "../../constant/Constant";
import {
  Gantt,
  Task,
  EventOption,
  StylingOption,
  ViewMode,
  DisplayOption,
} from "gantt-task-react";
import "gantt-task-react/dist/index.css";
// import { GanttChartHeader } from "../common/GanttChartHeader";
const task_labels = [
  "Not Started Task",
  "In Progress Task",
  "On Hold Task",
  "Completed Task",
  "Cancelled Task",
];
const task_colors = [
  chartColors?.not_started,
  chartColors?.in_progress,
  chartColors?.on_hold,
  chartColors?.completed,
  chartColors?.cancelled,
];
let tasks = [
  {
    start: new Date(2024, 5, 8),
    end: new Date(2024, 20, 10),
    name: "Idea",
    id: "Task 0",
    type: "task",
    progress: 45,
    // styles: { progressColor: "#FFBB54", progressSelectedColor: "#FF9E0D" },
  },
  {
    start: new Date(2024, 1, 11),
    end: new Date(2024, 1, 12),
    name: "Sorter",
    id: "Task 1",
    type: "task",
    progress: 80,
  },
  {
    start: new Date(2024, 3, 9),
    end: new Date(2024, 6, 10),
    name: "Joiner",
    id: "Task 0",
    type: "task",
    progress: 80,
  },
  {
    start: new Date(2024, 1, 1),
    end: new Date(2024, 4, 5),
    name: "Task 22",
    id: "Task 0",
    type: "task",
    progress: 45,
  },
  {
    start: new Date(2024, 1, 11),
    end: new Date(2024, 6, 8),
    name: "Oren",
    id: "Task 0",
    type: "task",
    progress: 45,
  },
];
const hours_labels = ["Estimated Time", "Hours Logged"];
const hours_colors = [chartColors?.on_hold, chartColors?.completed];
const CircularProgressBar = ({ progress, size, strokeWidth }) => {
  // Calculations for circle's circumference and stroke-dashoffset
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (progress / 100) * circumference;
  return (
    <div className="relative flex justify-center items-center">
      <span className="absolute text-lg font-semibold">{progress}</span>
      <svg width={size} height={size} className="transform -rotate-90">
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          stroke="#E2F3FF"
          strokeWidth={strokeWidth}
          fill="none"
        />
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          stroke="#2796C1"
          strokeWidth={strokeWidth}
          fill="none"
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          strokeLinecap="round"
          className="transition-stroke-dashoffse duration-300 ease-out cursor-pointer"
        />
      </svg>
    </div>
  );
};
const ProjectOverview = ({ project_id }) => {
  const { token } = useSelector((state) => state.auth);
  const [projectOverviewDetails, setProjectOverviewDetails] = useState(null);
  const [projectOverviewLoading, setprojectOverviewLoading] = useState(false);
  const [progressData, setProgressData] = useState();
  const [hoursData, setHoursData] = useState([]);
  const [taskData, setTaskData] = useState([]);
  const [milestones, setMilestones] = useState([]);
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(true);
  const [view, setView] = useState(ViewMode.Day);
  // let columnWidth = 100;
  // if (view === ViewMode.Month) {
  //   columnWidth = 300;
  // } else if (view === ViewMode.Week) {
  //   columnWidth = 250;
  // }

  const formattedMilestone = milestones?.map((item) => {
    return {
      start: new Date(item?.start_date),
      end: new Date(item?.deadline_date),
      name: item?.milestone_name,
      id: item?._id,
      progress: item?.completion_percentage,
      type: "task",
      styles: { progressColor: "#FFBB54", progressSelectedColor: "#FF9E0D" },
    };
  });

  const getProjectOverview = async () => {
    setprojectOverviewLoading(true);
    try {
      const project_overview_repsonse = await apiConnector(
        "GET",
        `${projectEndPoints?.PROJECT_DASHBOARD_API}/${project_id}`,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setProjectOverviewDetails(project_overview_repsonse?.data?.data);
      setProgressData(project_overview_repsonse?.data?.data?.project_progress);
      const estimatedHrs = parseInt(
        project_overview_repsonse?.data?.data?.estimated_time?.split(":")[0]
      );
      const hours_logged_hrs = parseInt(
        project_overview_repsonse?.data?.data?.hours_logged?.split(":")[0]
      );
      setHoursData([estimatedHrs, hours_logged_hrs]);
      setTaskData([
        project_overview_repsonse?.data?.data?.not_started_task,
        project_overview_repsonse?.data?.data?.in_progress_task,
        project_overview_repsonse?.data?.data?.on_hold_task,
        project_overview_repsonse?.data?.data?.completed_task,
        project_overview_repsonse?.data?.data?.cancelled_task,
      ]);
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setprojectOverviewLoading(false);
    }
  };
  const fetchMilestone = async () => {
    try {
      const response = await apiConnector(
        "GET",
        `${milestoneEndPoints?.MILESTONE_API}/${project_id}`,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setMilestones(response?.data?.data?.milestone);
    } catch (error) {
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    if (project_id) {
      getProjectOverview();
      fetchMilestone();
    }
  }, [project_id]);
  return (
    <div className="w-full bg-custom-white rounded-lg p-4 mb-6 flex flex-col gap-4">
      <section className="w-full h-[50%] border border-[#E9EDF0] rounded-lg p-4">
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4 ">
          <Heading
            label="Project Manager"
            value={
              projectOverviewLoading ? (
                <Skeleton width={150} />
              ) : (
                projectOverviewDetails?.project_manager?.user_name
              )
            }
          />
          <Heading
            label="Email"
            value={
              projectOverviewLoading ? (
                <Skeleton width={200} />
              ) : (
                projectOverviewDetails?.project_manager?.email
              )
            }
          />
          <Heading
            label="Start Date"
            value={
              projectOverviewLoading ? (
                <Skeleton width={100} />
              ) : projectOverviewDetails?.project_start_date ? (
                dateFormatter(projectOverviewDetails?.project_start_date, {
                  format: "MMM DD, YYYY",
                  includeTimeForToday: false,
                })
              ) : (
                "--"
              )
            }
          />
          <Heading
            label="End Date"
            value={
              projectOverviewLoading ? (
                <Skeleton width={100} />
              ) : projectOverviewDetails?.project_deadline ? (
                <>
                  {dateFormatter(projectOverviewDetails?.project_deadline, {
                    format: "MMM DD, YYYY",
                    includeTimeForToday: false,
                  })}{" "}
                  (
                  {getDateDifferenceStatus(
                    projectOverviewDetails?.project_deadline,
                    projectOverviewDetails?.projectStatus === "Completed"
                      ? projectOverviewDetails?.completed_on
                      : null
                  )}
                  )
                </>
              ) : (
                "--"
              )
            }
          />
          <Heading
            label="Status"
            value={
              projectOverviewLoading ? (
                <Skeleton width={120} />
              ) : (
                <CustomStatusButton
                  selectedStatus={projectOverviewDetails?.projectStatus}
                />
              )
            }
          />
        </div>
        <div className="w-full h-[1px] bg-slate-200 my-2" />
        <div className="w-full">
          <h2 className="text-custom-black text-lg font-semibold text-justify">
            {projectOverviewLoading ? (
              <Skeleton width={200} />
            ) : (
              projectOverviewDetails?.project_name
            )}
          </h2>
          <div className="text-sm text-[#646464] text-justify w-full h-40 overflow-auto">
            {projectOverviewLoading ? (
              <Skeleton count={3} />
            ) : (
              projectOverviewDetails?.project_description
            )}
          </div>
        </div>
      </section>
      <section className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {/* Task Chart */}
        <div className="relative border border-[#E9EDF0] rounded-lg p-4 flex flex-col justify-between items-center h-full md:h-96">
          <h1 className="font-semibold text-sm my-2 text-slate-500">Tasks</h1>
          {projectOverviewLoading ? (
            <Skeleton width={250} height={150} />
          ) : projectOverviewDetails?.total_task > 0 ? (
            <CustomChart
              labelName={""}
              chartType="doughnut"
              labels={task_labels}
              data={taskData}
              colors={task_colors}
            />
          ) : (
            <span>No Task Available</span>
          )}
          <h1>Total Task - {projectOverviewDetails?.total_task}</h1>
        </div>
        {/* Hours Chart */}
        <div className="border border-[#E9EDF0] rounded-lg p-4 flex flex-col justify-between items-center h-full md:h-96">
          <h1 className="font-semibold text-sm my-2 text-slate-500">Hours</h1>
          {projectOverviewLoading ? (
            <Skeleton width={250} height={150} />
          ) : (
            <CustomChart
              labelName=""
              chartType="bar"
              labels={hours_labels}
              data={hoursData}
              colors={hours_colors}
            />
          )}
          <div className="w-full flex justify-between items-center gap-5">
            <Heading
              label="Estimated Time"
              value={
                projectOverviewLoading ? (
                  <Skeleton width={120} />
                ) : (
                  projectOverviewDetails?.estimated_time + " hrs"
                )
              }
            />
            <Heading
              label="Hours Logged"
              value={
                projectOverviewLoading ? (
                  <Skeleton width={120} />
                ) : (
                  projectOverviewDetails?.hours_logged + " hrs"
                )
              }
            />
          </div>
        </div>
        {/* Project Progress */}
        <div className="border border-[#E9EDF0] rounded-lg p-4 flex flex-col justify-between items-center h-full md:h-96">
          <h1 className="font-semibold text-sm my-2 text-slate-500">
            Project Progress
          </h1>
          {projectOverviewLoading ? (
            <Skeleton circle={true} width={150} height={150} />
          ) : projectOverviewDetails?.project_start_date &&
            projectOverviewDetails?.project_deadline ? (
            <CircularProgressBar
              progress={progressData}
              size={150}
              strokeWidth={10}
            />
          ) : (
            "No Data Available"
          )}
          <div className="w-full flex justify-between items-center gap-5">
            <Heading
              label="Start Date"
              value={
                projectOverviewLoading ? (
                  <Skeleton width={100} />
                ) : projectOverviewDetails?.project_start_date ? (
                  dateFormatter(projectOverviewDetails?.project_start_date)
                ) : (
                  "--"
                )
              }
            />
            <Heading
              label="End Date"
              value={
                projectOverviewLoading ? (
                  <Skeleton width={100} />
                ) : projectOverviewDetails?.project_deadline ? (
                  dateFormatter(projectOverviewDetails?.project_deadline)
                ) : (
                  "--"
                )
              }
            />
          </div>
        </div>
      </section>
      {/* <GanttChartHeader
        onViewModeChange={(viewMode) => setView(viewMode)}
        onViewListChange={setIsChecked}
        isChecked={isChecked}
      /> */}
      {formattedMilestone?.length && (
        <Gantt
          // TaskListHeader={}
          tasks={formattedMilestone}
          viewMode="Month"
          columnWidth={300}
          hideChildren={true}
        />
      )}
    </div>
  );
};
export default ProjectOverview;
