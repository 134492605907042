import React, { useEffect, useRef, useState } from "react";
import {
  CustomActionDropDown,
  CustomButton,
  CustomDrawer,
  CustomInput,
  CustomSelect,
  CustomSwitch,
  NoDataFound,
  Pagination,
  PaginationText,
  TableHeader,
} from "../common";
import { IoMdSearch } from "react-icons/io";
import { MdOutlineEdit, MdOutlineFilterAlt } from "react-icons/md";
import { FaPlusCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import useOnClickOutside from "../../helper/onClickOutside";
import { useDispatch, useSelector } from "react-redux";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { fetchAllEmployees } from "../../redux/slice/employeeSlice";
import Skeleton from "react-loading-skeleton";
import { Roles } from "../../constant/Constant";
import { toast } from "react-toastify";
import { fetchDepartment } from "../../redux/slice/departmentSlice";
import { fetchDesignation } from "../../redux/slice/designationSlice";
import { dateFormatter } from "../../helper/formatDate";
import { GrView } from "react-icons/gr";
import { useForm } from "react-hook-form";
import { setEmployeeFilter } from "../../redux/slice/filterSlice";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const employeeStatusOptions = [
  { name: "All", value: "" },
  { name: "Active", value: true },
  { name: "InActive", value: false },
];
const Employees = () => {
  const navigate = useNavigate(),
    dispatch = useDispatch(),
    drawerRef = useRef(),
    { token, user } = useSelector((state) => state.auth),
    { departmentData } = useSelector((state) => state.department),
    { employeeLoading } = useSelector((state) => state.employee),
    { employeeFilter } = useSelector((state) => state.filter),
    [itemsPerPage, setItemsPerPage] = useState(10),
    [currentPage, setCurrentPage] = useState(1),
    [employeeCount, SetEmployeeCount] = useState(0),
    [searchString, setSearchString] = useState(""),
    [designations, setDesignations] = useState([]),
    [employees, setEmployees] = useState([]),
    [tableData, setTableData] = useState([]),
    [openFilterDrawer, setOpenFilterDrawer] = useState(false);

  const { control, handleSubmit, reset, watch } = useForm({
    department: employeeFilter?.department ? employeeFilter?.department : "",
    designation: employeeFilter?.designation ? employeeFilter?.designation : "",
    status: employeeFilter?.status ? employeeFilter?.status : "",
  });

  const TableHeads = [
    "Employee ID",
    "Name",
    "Email",
    "Contact Number",
    "Department",
    "Designation",
    "Last Login",
    "Status",
    "Actions",
  ];

  useOnClickOutside(drawerRef, () => setOpenFilterDrawer(false));

  const fetchEmployees = async (
    user_name,
    department,
    designation,
    status = ""
  ) => {
    try {
      const response = await dispatch(
        fetchAllEmployees({
          token,
          user_name,
          department,
          designation,
          status,
          currentPage,
          itemsPerPage,
        })
      ).unwrap();

      if (response?.data) {
        setEmployees(response.data.employees);
        setTableData(response.data.employees);
        SetEmployeeCount(response.data.employee_count);
      }
      setOpenFilterDrawer(false);
    } catch (error) {
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, [currentPage, itemsPerPage]);

  const filterEmployee = (e) => {
    const val = e?.target?.value;
    setSearchString(e?.target?.value);
    if (val?.length > 0) {
      const filterCompanies = employees?.filter(
        (item) =>
          item?.user_name?.toLowerCase().includes(val.toLowerCase()) ||
          item?.email?.toLowerCase() === val.toLowerCase() ||
          item?.mobile_number?.toString().includes(val.toLowerCase()) ||
          item?.department?.department_name
            ?.toLowerCase()
            .includes(val.toLowerCase()) ||
          item?.designation?.designation
            ?.toLowerCase()
            .includes(val.toLowerCase())
      );
      setTableData(filterCompanies);
    } else {
      setTableData(employees);
    }
  };
  const pageCount = Math.ceil(employeeCount / itemsPerPage);
  const handlePageClick = (event) => {
    setCurrentPage(event?.selected + 1);
  };
  console.log(employeeFilter, "employeeFilter");

  const fetchDepartments = async () => {
    try {
      await dispatch(fetchDepartment({ token }));
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const fetchDesignations = async () => {
    try {
      const response = await dispatch(fetchDesignation({ token })).unwrap();

      if (response?.data) {
        setDesignations(response.data.designation);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };
  useEffect(() => {
    fetchDepartments();
    fetchDesignations();
  }, []);

  const departmentOptions = departmentData?.data?.departments?.map(
    (department) => ({
      name: department?.department_name,
      value: department?._id,
    })
  );
  departmentOptions?.unshift({ name: "All", value: "" });
  const designationOptions = designations?.map((designation) => ({
    name: designation?.designation,
    value: designation?._id,
  }));
  designationOptions?.unshift({ name: "All", value: "" });
  const selectedName = watch("user_name");
  console.log(selectedName, "name");
  const selectedDepartement = watch("department");
  const selectedDesignation = watch("designation");
  const selectedStatus = watch("status");
  useEffect(() => {
    if (
      // !employeeFilter?.department &&
      // !employeeFilter?.designation &&
      // !employeeFilter?.status
      true
    ) {
      const filters = {
        ...(selectedName && { user_name: selectedName }),
        ...(selectedDepartement && { department: selectedDepartement }),
        ...(selectedDesignation && { designation: selectedDesignation }),
        ...(typeof selectedStatus === "boolean" && { status: selectedStatus }),
      };
      console.log(filters, "Opopopop");
      dispatch(setEmployeeFilter(filters));
    }
  }, [selectedDepartement, selectedDesignation, selectedStatus, selectedName]);

  const filterEmployeeApiHandler = () => {
    const { department, designation, status } = employeeFilter;
    fetchEmployees(department, designation, status);
  };

  return (
    <div className="w-full h-full bg-custom-white rounded-md">
      <section className="flex md:flex-row flex-col gap-2 justify-between bg-custom-white items-center p-2 rounded-t-md">
        <h1 className="font-semibold">Employee List</h1>
        <div className="flex md:flex-row flex-col justify-center items-center gap-4">
          <div className="relative ">
            <IoMdSearch className="absolute z-10 top-3 left-1 text-slate-400" />
            <input
              name="searchString"
              label=""
              type="text"
              placeholder="Search"
              onChange={(e) => filterEmployee(e)}
              value={searchString}
              className="bg-custom-white px-5 py-2 text-sm font-semibold border outline-none rounded-md"
            />
          </div>
          <CustomButton
            title={
              <div className="relative flex justify-center items-center font-semibold">
                {(employeeFilter?.department ||
                  employeeFilter?.designation ||
                  employeeFilter?.status) && (
                  <div className="absolute w-5 h-5 flex justify-center items-center rounded-full bg-green-500 -right-4 -top-4 text-xs font-semibold text-custom-white">
                    {Object.keys(employeeFilter).length}
                  </div>
                )}
                <MdOutlineFilterAlt size={20} />
                <span>Filters</span>
              </div>
            }
            buttonType="submit"
            onClick={() =>
              setOpenFilterDrawer((openFilterDrawer) => !openFilterDrawer)
            }
            classname="bg-custom-white text-slate-400 p-2 text-sm font-semibold rounded-md border"
          />
          {user?.roleId !== Roles?.employee && (
            <CustomButton
              title={
                <div className="flex justify-center items-center gap-2 ">
                  <FaPlusCircle />
                  <span>Add Employee</span>
                </div>
              }
              buttonType="button"
              onClick={() => navigate("/employees/create")}
              classname="bg-gradient-custom text-custom-white font-semibold text-sm px-5 py-2 rounded-md"
            />
          )}
        </div>
        {openFilterDrawer && (
          <CustomDrawer
            ref={drawerRef}
            open={openFilterDrawer}
            setOpen={setOpenFilterDrawer}
            filterName="Employee Filter"
          >
            {/* <div className="my-4">
              <CustomInput
                name="user_name"
                label="Name"
                inputType="text"
                placeholder="John.doe"
                control={control}
              />
            </div> */}
            <div className="my-4">
              <CustomSelect
                name="department"
                label="Department"
                inputType="text"
                placeholder="Select..."
                control={control}
                options={departmentOptions}
              />
            </div>
            <div className="my-4">
              <CustomSelect
                name="designation"
                label="Designation"
                inputType="text"
                placeholder="Select..."
                control={control}
                options={designationOptions}
              />
            </div>
            <div className="my-4">
              <CustomSelect
                name="status"
                label="Status"
                inputType="text"
                placeholder="Select..."
                control={control}
                options={employeeStatusOptions}
              />
            </div>
            <div className="w-full absolute bottom-0 right-0 p-4">
              <div className="w-full h-[1px] bg-slate-200 my-2" />
              <div className="flex items-center justify-between ">
                <CustomButton
                  title={"Clear"}
                  onClick={() => {
                    reset({
                      department: "",
                      designation: "",
                      status: "",
                    });
                    fetchEmployees();
                    setOpenFilterDrawer(false);
                  }}
                  buttonType="submit"
                  classname={"border px-5 py-1 rounded-md"}
                />
                <CustomButton
                  title={"Apply"}
                  onClick={() => {
                    filterEmployeeApiHandler();
                  }}
                  buttonType="submit"
                  classname={
                    "border px-5 py-1 rounded-md bg-custom-status-completed text-custom-white"
                  }
                />
              </div>
            </div>
          </CustomDrawer>
        )}
      </section>
      <section className="w-full">
        <table className="w-full border rounded-lg bg-custom-white overflow-auto">
          <thead>
            <TableHeader TableHeads={TableHeads} />
          </thead>
          {employeeLoading ? (
            <tbody>
              <tr>
                <td
                  colSpan={TableHeads.length}
                  className="text-center font-semibold text-sm p-3"
                >
                  <Skeleton
                    count={5}
                    width={"100%"}
                    height={"22px"}
                    className="my-2"
                  />
                </td>
              </tr>
            </tbody>
          ) : tableData && tableData?.length > 0 ? (
            <tbody>
              {tableData?.map((item, index) => {
                return (
                  <tr
                    key={item?._id}
                    className="w-full border-b hover:bg-slate-100"
                  >
                    <td className="p-2 text-sm">{item?.emp_id}</td>
                    <td className="p-2 text-sm">
                      <div
                        onClick={() =>
                          navigate("/employees/view", {
                            state: { id: item?._id },
                          })
                        }
                        className="flex justify-start items-center gap-4 cursor-pointer"
                      >
                        <img
                          src={
                            item?.avatar
                              ? `${BASE_URL}/${item?.avatar}`
                              : `https://api.dicebear.com/5.x/initials/svg?seed=${item?.user_name}`
                          }
                          alt={item?.user_name}
                          className="w-7 h-7 rounded-full bg-slate-300 p-0.5"
                        />
                        <span>{item?.user_name}</span>
                      </div>
                    </td>
                    <td className="p-2 text-sm w-32 ">
                      <div className="ellipsis">{item?.email}</div>
                    </td>
                    <td className="p-2 text-sm">{item?.mobile_number}</td>
                    <td className="p-2 text-sm">
                      {item?.roleId === Roles?.admin
                        ? "Admin"
                        : item?.department?.department_name ?? "-"}
                    </td>
                    <td className="p-2 text-sm">
                      {item?.roleId === Roles?.admin
                        ? "Admin"
                        : item?.designation?.designation ?? "-"}
                    </td>
                    <td className="p-2 text-sm">
                      {item?.last_login
                        ? dateFormatter(item?.last_login, {
                            format: "MMM D, YYYY hh:mm A",
                            includeTimeForToday: true,
                          })
                        : "--"}
                    </td>
                    <td>
                      <div className="flex items-center text-sm text-start gap-2">
                        <div
                          className={`w-3 h-3  rounded-full ${
                            item?.isActive ? "bg-green-500" : "bg-red-500"
                          }`}
                        />
                        <span>{item?.isActive ? "Active " : "Inactive"}</span>
                      </div>
                    </td>
                    <td className="p-2 flex justify-center items-center gap-2 cursor-pointer">
                      <div
                        onClick={() =>
                          navigate("/employees/view", {
                            state: { id: item?._id },
                          })
                        }
                        className="p-1 border border-custom-blue rounded-md cursor-pointer"
                      >
                        <GrView className="text-custom-blue" />
                      </div>
                      {user?.roleId === Roles?.admin && (
                        <div
                          onClick={() => {
                            navigate("/employees/create", {
                              state: { employee: item },
                            });
                          }}
                          className="p-1 border border-custom-blue rounded-md cursor-pointer"
                        >
                          <MdOutlineEdit className="text-custom-blue" />
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td
                  colSpan={TableHeads.length}
                  className="text-center font-semibold text-sm p-4"
                >
                  <NoDataFound />
                </td>
              </tr>
            </tbody>
          )}
          <tfoot>
            <tr className="relative">
              <td className="text-slate-400 text-sm px-2 py-4" colSpan={6}>
                <PaginationText
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  dataCount={employeeCount}
                  dataLength={employees?.length}
                />
              </td>
              <td className="absolute bottom-0 right-2 top-1.5">
                <Pagination
                  handlePageClick={handlePageClick}
                  pageRangeDisplayed={itemsPerPage}
                  pageCount={pageCount}
                  name={"pageSize"}
                  onChange={(e) => {
                    setItemsPerPage(e?.target?.value);
                  }}
                />
              </td>
            </tr>
          </tfoot>
        </table>
      </section>
    </div>
  );
};

export default Employees;
