import React from "react";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const { token, user } = useSelector((state) => state.auth);
  return (
    <div className="w-full">
      <section className="w-full bg-custom-white border border-slate-200 p-3 rounded-md">
        <h2 className="text-sm md:text-lg">Good Morning,{user?.user_name}</h2>
      </section>
    </div>
  );
};

export default Dashboard;
