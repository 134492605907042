import React, { useRef } from "react";

const CommonTooltip = ({ tooltip, children }) => {
  const parentRef = useRef();
  const tooltipRef = useRef();
  return (
    <div
      ref={parentRef}
      onMouseEnter={({ clientX, clientY }) => {
        if (!tooltipRef.current || !parentRef?.current) return;
        const { left, top } = parentRef?.current.getBoundingClientRect();

        const tooltipX = clientX - left;
        const tooltipY = clientY - top;

        tooltipRef.current.style.left = `${tooltipX}px`;
        tooltipRef.current.style.top = `-100%`;
      }}
      className="group relative inline-block"
    >
      {children}
      {tooltip ? (
        <span
          ref={tooltipRef}
          className="text-xs shadow-sm  font-semibold z-[999] invisible group-hover:visible opacity-0 group-hover:opacity-100 transition bg-gray-900 dark:bg-gray-700 text-custom-white p-1 rounded absolute top-full mt-2 whitespace-nowrap"
        >
          {tooltip}
        </span>
      ) : null}
    </div>
  );
};

export default CommonTooltip;
