import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  employeeFilter: null,
  projectFilter: null,
  taskFilter: null,
  subTaskFilter: null,
  departmentFilter: null,
  designationFilter: null,
  officeLocationFilter: null,
};

const filterSlice = createSlice({
  name: "filter",
  initialState: initialState,
  reducers: {
    setEmployeeFilter(state, value) {
      state.employeeFilter = value.payload;
    },
    setProjectFilter(state, value) {
      state.projectFilter = value.payload;
    },
    setTaskFilter(state, value) {
      state.taskFilter = value.payload;
    },
    setSubTaskFilter(state, value) {
      state.subTaskFilter = value.payload;
    },
    setDepartmentFilter(state, value) {
      state.departmentFilter = value.payload;
    },
    setDesignationFilter(state, value) {
      state.designationFilter = value.payload;
    },
    setOfficeLocationFilter(state, value) {
      state.officeLocationFilter = value.payload;
    },
  },
});

export const {
  setDepartmentFilter,
  setDesignationFilter,
  setEmployeeFilter,
  setOfficeLocationFilter,
  setProjectFilter,
  setSubTaskFilter,
  setTaskFilter,
} = filterSlice.actions;
export default filterSlice.reducer;
