import React from "react";
import { MdOutlineNavigateBefore, MdOutlineNavigateNext } from "react-icons/md";
import ReactPaginate from "react-paginate";

const Pagination = ({
  handlePageClick,
  pageRangeDisplayed = 5,
  pageCount,
  onChange,
  name,
}) => {
  const options = [
    {
      id: 1,
      name: "10",
      value: 10,
    },
    {
      id: 2,
      name: "20",
      value: 20,
    },
    {
      id: 3,
      name: "50",
      value: 50,
    },
    {
      id: 3,
      name: "100",
      value: 100,
    },
  ];
  return (
    <div className="flex justify-center items-center gap-2">
      <span>Display - </span>
      <select
        name={name}
        onChange={onChange}
        className="bg-custom-white p-1 border outline-none rounded-md cursor-pointer"
      >
        {options?.map((option) => (
          <option key={option?.id} value={option?.value}>
            {option?.name}
          </option>
        ))}
      </select>

      <ReactPaginate
        breakLabel="..."
        onPageChange={handlePageClick}
        pageRangeDisplayed={pageRangeDisplayed}
        pageCount={pageCount}
        renderOnZeroPageCount={null}
        pageClassName="rounded-md flex justify-center items-center border text-[#738182] font-medium"
        activeClassName="bg-custom-blue text-custom-white"
        pageLinkClassName="px-2.5 py-0.5"
        className="flex justify-end items-end gap-3 rounded-md p-1"
        previousLabel={
          <MdOutlineNavigateBefore className="border p-1 text-3xl rounded-md bg-[#E4E8EB]" />
        }
        nextLabel={
          <MdOutlineNavigateNext className="border p-1 text-3xl rounded-md bg-[#E4E8EB]" />
        }
      />
    </div>
  );
};

export default Pagination;
