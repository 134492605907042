import React, { useEffect, useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { matchPath, useLocation, useNavigate } from "react-router-dom";

const CustomSidebar = ({ menuItems }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [openSubItems, setOpenSubItems] = useState(false);

  const matchRoute = (item) => {
    if (!item) return false;
    const { path, active } = item;

    const isPathMatch = path ? matchPath(path, location?.pathname) : false;
    const isActiveMatch = active
      ? active.some((activePath) => matchPath(activePath, location?.pathname))
      : false;

    return isPathMatch || isActiveMatch;
  };

  useEffect(() => {
    if (
      location?.pathname === "/department" ||
      location?.pathname === "/designation" ||
      location?.pathname === "/office_location" ||
      location?.pathname === "/company-profile"
    ) {
      setOpenSubItems(true);
    }
  }, [location]);

  return (
    <div
      className={`w-64 h-full border-r shadow-md bg-custom-white absolute z-50 lg:static transition-transform transform duration-500 delay-300 lg:transform-none`}
    >
      {menuItems.map((item, index) => (
        <div
          key={index}
          className={`${index === 0 ? "border-t border-b" : "border-b"}`}
        >
          <div
            key={index}
            className={`${
              matchRoute(item) &&
              "text-custom-blue bg-[#E3EFF8] border-l-4 border-l-custom-blue"
            } flex justify-between items-center gap-4 px-5 py-3 font-medium cursor-pointer  `}
            onClick={() => {
              if (item?.subItems) {
                setOpenSubItems((openSubItems) => !openSubItems);
              } else {
                setOpenSubItems(false);
                item?.path && navigate(item.path);
              }
            }}
          >
            <div className="flex items-center gap-4">
              <span className="text-xl">{item.icon}</span>
              <span>{item.name}</span>
            </div>
            <span>
              {item?.subItems ? (
                openSubItems ? (
                  <FaAngleUp />
                ) : (
                  <FaAngleDown />
                )
              ) : (
                ""
              )}
            </span>
          </div>
          {item?.subItems &&
            openSubItems &&
            item?.subItems?.map((subItem, index) => (
              <div
                key={index}
                className={`${
                  matchRoute(subItem) &&
                  "text-custom-blue bg-[#E3EFF8] border-l-4 border-custom-blue"
                } flex justify-between items-center gap-4 px-8 py-3 font-medium cursor-pointer `}
                onClick={() => {
                  subItem?.path && navigate(subItem.path);
                }}
              >
                <div className="flex items-center gap-4">
                  <span className="text-xl">{subItem.icon}</span>
                  <span>{subItem.name}</span>
                </div>
                {subItem?.subItems && <FaAngleDown />}
              </div>
            ))}
        </div>
      ))}
    </div>
  );
};

export default CustomSidebar;
