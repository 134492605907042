import React from "react";
import {
  Header,
  Business,
  Customer,
  Footer,
  Herosection,
  Needssection,
  Teamsection,
} from "../components/landing_page";

const LandingPage = () => {
  return (
    <div className="w-full h-full">
      <Header />
      <Herosection />
      <Needssection />
      <Business />
      <Teamsection />
      <Customer />
      <Footer />
    </div>
  );
};

export default LandingPage;
