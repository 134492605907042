import React, { useEffect, useRef, useState } from "react";
import {
  CustomActionDropDown,
  CustomButton,
  CustomModal,
  NoDataFound,
  Pagination,
  PaginationText,
  TableHeader,
} from "../../common";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { MdOutlineEdit, MdOutlineFilterAlt } from "react-icons/md";
import { IoMdSearch } from "react-icons/io";
import { FaPlusCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import useOnClickOutside from "../../../helper/onClickOutside";
import { dateFormatter } from "../../../helper/formatDate";
import Skeleton from "react-loading-skeleton";
import CreateOfficeLocation from "./CreateOfficeLocation";
import { fetchOfficeLocation } from "../../../redux/slice/locationSlice";
import { toast } from "react-toastify";
import { setLoading } from "../../../redux/slice/authSlice";
import { apiConnector } from "../../../networking/ApiConnector";
import { officeLocationEndPoints } from "../../../networking/Endpoints";
import { RiDeleteBinLine } from "react-icons/ri";

const OfficeLocation = () => {
  const actionRef = useRef(),
    dispatch = useDispatch(),
    { token } = useSelector((state) => state.auth),
    { officeLocationLoading } = useSelector((state) => state.officeLocation),
    [showOfficeLocationModal, setShowOfficeLocationModal] = useState(false),
    [officeLocations, setOfficeLocations] = useState([]),
    [officeLocationTableData, setOfficeLocationTableData] = useState([]),
    [officeLocationCount, setOfficeLocationCount] = useState(0),
    [searchString, setSearchString] = useState(""),
    [officeLocationDetails, setOfficeLocationDetails] = useState(null),
    [openDropdownIndex, setOpenDropdownIndex] = useState(null),
    [itemsPerPage, setItemsPerPage] = useState(10),
    [currentPage, setCurrentPage] = useState(1);
  const [showOfficeLocationDeleteModal, setShowOfficeLocationDeleteModal] =
    useState(false);
  const TableHeads = [
    "S.No",
    "City",
    "Address",
    "Created At",
    "Last Updated At",
    "Status",
    "Actions",
  ];

  useOnClickOutside(actionRef, () => setOpenDropdownIndex(null));

  const fetchOffices = async () => {
    try {
      const response = await dispatch(
        fetchOfficeLocation({ token, currentPage, itemsPerPage })
      ).unwrap();

      if (response?.data) {
        setOfficeLocations(response.data.offices);
        setOfficeLocationTableData(response.data.offices);
        setOfficeLocationCount(response.data.office_count);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };
  useEffect(() => {
    fetchOffices();
  }, [currentPage, itemsPerPage]);

  const filterOffices = (e) => {
    const val = e?.target?.value;
    setSearchString(e?.target?.value);
    if (val?.length > 0) {
      const filteredOffices = officeLocations?.filter(
        (item) =>
          item?.city.toLowerCase().includes(val.toLowerCase()) ||
          item?.address.toLowerCase().includes(val.toLowerCase()) ||
          dateFormatter(item?.createdAt)
            .toLowerCase()
            .includes(val.toLowerCase()) ||
          dateFormatter(item?.updatedAt)
            .toLowerCase()
            .includes(val.toLowerCase())
      );
      setOfficeLocationTableData(filteredOffices);
    } else {
      setOfficeLocationTableData(officeLocations);
    }
  };

  const pageCount = Math.ceil(officeLocationCount / itemsPerPage);

  const handlePageClick = (event) => {
    setCurrentPage(event?.selected + 1);
  };
  const deleteOfficeLocation = async (office_location_id) => {
    dispatch(setLoading(true));
    try {
      const department_response = await apiConnector(
        "DELETE",
        `${officeLocationEndPoints?.OFFICE_LOCATION_API}/${office_location_id}`,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      toast.success(department_response?.data?.message);
      if (fetchOffices) {
        fetchOffices();
      }
      setShowOfficeLocationDeleteModal(false);
    } catch (error) {
      toast.error(error?.message);
    } finally {
      dispatch(setLoading(false));
    }
  };
  return (
    <div className="w-full h-full">
      <section className="flex md:flex-row flex-col gap-2 justify-between bg-custom-white items-center p-2 rounded-t-md">
        <h1 className="font-semibold text-lg">Office Location List</h1>
        <div className="flex md:flex-row flex-col justify-center items-center gap-4">
          <div className="relative">
            <IoMdSearch className="absolute z-10 top-3 left-1 text-slate-400" />
            <input
              name="searchString"
              type="text"
              placeholder="Search"
              onChange={(e) => filterOffices(e)}
              value={searchString}
              className="border px-6 py-2 font-semibold text-sm outline-none rounded-md"
            />
          </div>
          <CustomButton
            title={
              <div className="flex justify-center items-center font-semibold">
                <MdOutlineFilterAlt size={20} />
                <span>Filters</span>
              </div>
            }
            buttonType="submit"
            classname="bg-custom-white text-slate-400 p-2 font-semibold text-sm rounded-md border "
          />
          <CustomButton
            title={
              <div className="flex justify-center items-center gap-2 ">
                <FaPlusCircle />
                <span>Add Office Location</span>
              </div>
            }
            buttonType="button"
            onClick={() =>
              setShowOfficeLocationModal(
                (showOfficeLocationModal) => !showOfficeLocationModal
              )
            }
            classname="bg-gradient-custom text-custom-white font-semibold text-sm px-5 py-2 rounded-md"
          />
        </div>
      </section>
      <section className="w-full">
        <table className="w-full border bg-custom-white rounded-lg overflow-auto">
          <thead>
            <TableHeader TableHeads={TableHeads} />
          </thead>
          {officeLocationLoading ? (
            <tbody>
              <tr>
                <td
                  colSpan={TableHeads.length}
                  className="text-center font-semibold text-sm p-3"
                >
                  <Skeleton
                    count={5}
                    width={"100%"}
                    height={"22px"}
                    className="my-2"
                  />
                </td>
              </tr>
            </tbody>
          ) : officeLocationTableData && officeLocationTableData?.length > 0 ? (
            <>
              <tbody>
                {officeLocationTableData?.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className="w-full border-b hover:bg-slate-100"
                    >
                      <td className="p-3 text-sm font-medium">
                        {(currentPage - 1) * itemsPerPage + index + 1}
                      </td>
                      <td className="p-3 text-sm">{item?.city ?? "-"}</td>
                      <td className="p-3 text-sm line-c">
                        {item?.address ?? "-"}
                      </td>
                      <td className="p-3 text-sm">
                        {item?.createdAt
                          ? dateFormatter(item?.createdAt, {
                              format: "MMM DD, YYYY hh:mm A",
                              includeTimeForToday: true,
                            })
                          : "--"}
                      </td>
                      <td className="p-3 text-sm">
                        {item?.createdAt !== item?.updatedAt
                          ? dateFormatter(item?.updatedAt, {
                              format: "MMM DD, YYYY hh:mm A",
                              includeTimeForToday: true,
                            })
                          : "-"}
                      </td>
                      <td>
                        <div className="flex items-center text-sm text-start gap-2">
                          <div
                            className={`w-3 h-3  rounded-full ${
                              item?.isActive ? "bg-green-500" : "bg-red-500"
                            }`}
                          />
                          <span>{item?.isActive ? "Active " : "Inactive"}</span>
                        </div>
                      </td>
                      <td className="p-3 flex justify-center items-center cursor-pointer">
                        <div
                          onClick={() => {
                            setOfficeLocationDetails(item);
                            setShowOfficeLocationModal(true);
                          }}
                          className="p-1 border border-custom-blue rounded-md cursor-pointer"
                        >
                          <MdOutlineEdit className="text-custom-blue" />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </>
          ) : (
            <tbody>
              <tr>
                <td
                  colSpan={TableHeads?.length}
                  className="text-center font-semibold text-sm p-4"
                >
                  <NoDataFound />
                </td>
              </tr>
            </tbody>
          )}
          <tfoot className="relative">
            <tr>
              <td className="text-slate-400 text-sm px-2 py-4" colSpan={6}>
                <PaginationText
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  dataCount={officeLocationCount}
                  dataLength={officeLocations?.length}
                />
              </td>
              <td className="absolute right-2 top-1.5">
                <Pagination
                  handlePageClick={handlePageClick}
                  pageRangeDisplayed={itemsPerPage}
                  pageCount={pageCount}
                  name={"pageSize"}
                  onChange={(e) => setItemsPerPage(e?.target?.value)}
                />
              </td>
            </tr>
          </tfoot>
        </table>
      </section>
      {showOfficeLocationModal && (
        <CreateOfficeLocation
          officeLocationDetails={officeLocationDetails}
          setOfficeLocationDetails={setOfficeLocationDetails}
          showOfficeLocationModal={showOfficeLocationModal}
          setShowOfficeLocationModal={setShowOfficeLocationModal}
          getDesignation={fetchOffices}
        />
      )}
    </div>
  );
};

export default OfficeLocation;
