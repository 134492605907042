import React from "react";

const Footer = () => {
  return (
    <div className="w-full text-[#666666] bg-slate-200 text-sm text-center underline p-2">
      @2024 Project Management System. All Right Reserved.
    </div>
  );
};

export default Footer;
