// import dayjs from "dayjs";
// import utc from "dayjs/plugin/utc";
// import timezone from "dayjs/plugin/timezone";
// import isToday from "dayjs/plugin/isToday";
// import isYesterday from "dayjs/plugin/isYesterday";

// dayjs.extend(utc);
// dayjs.extend(timezone);
// dayjs.extend(isToday);
// dayjs.extend(isYesterday);

// export function dateFormatter(
//   dateString,
//   options = { format: "MMM DD, YYYY", includeTimeForToday: true }
// ) {
//   const date = dayjs(dateString).tz("Asia/Kolkata");

//   const { format, includeTimeForToday } = options;

//   if (date.isToday()) {
//     return includeTimeForToday ? `Today ${date.format("HH:mm A")}` : `Today`;
//   } else if (date.isYesterday()) {
//     return includeTimeForToday
//       ? `Yesterday ${date.format("HH:mm A")}`
//       : "Yesterday";
//   }

//   return date.format(format);
// }

// export const groupByDate = (items) => {
//   if (!items || items.length === 0) return {};

//   return items.reduce((acc, note) => {
//     const noteDate = dayjs(note.createdAt);
//     let displayDate;

//     if (noteDate.isToday()) {
//       displayDate = "Today";
//     } else if (noteDate.isYesterday()) {
//       displayDate = "Yesterday";
//     } else {
//       displayDate = noteDate.format("MMM DD, YYYY");
//     }

//     if (!acc[displayDate]) {
//       acc[displayDate] = [];
//     }
//     acc[displayDate].push(note);
//     return acc;
//   }, {});
// };

// // export function getDateDifferenceStatus(dueDate, completedDate = null) {
// //   const due = dayjs(dueDate);
// //   const referenceDate = completedDate ? dayjs(completedDate) : dayjs();

// //   // Calculate difference in days
// //   const daysDiff = referenceDate.diff(due, "day");

// //   // Determine the output text based on days difference
// //   let outputText;

// //   // For completed tasks
// //   if (completedDate) {
// //     if (daysDiff < 0) {
// //       const absDiff = Math.abs(daysDiff);
// //       outputText = `Completed before ${formatTimeDiff(absDiff)}`;
// //     } else if (daysDiff === 0) {
// //       outputText = "Completed on the same day";
// //     } else {
// //       outputText = `Completed after ${formatTimeDiff(daysDiff)}`;
// //     }
// //   } else {
// //     // For incomplete tasks
// //     if (daysDiff < 0) {
// //       outputText = `${formatTimeDiff(Math.abs(daysDiff))} remaining`;
// //     } else {
// //       outputText = `Missed by ${formatTimeDiff(daysDiff)} ago.`;
// //     }
// //   }

// //   return (
// //     <span
// //       className={`${
// //         completedDate && daysDiff < 0 ? "text-red-500" : "text-green-500"
// //       } text-xs font-semibold`}
// //     >
// //       {outputText}
// //     </span>
// //   );
// // }
// // function formatTimeDiff(days) {
// //   if (days >= 365) {
// //     const years = Math.floor(days / 365);
// //     return `${years} year${years > 1 ? "s" : ""}`;
// //   } else if (days >= 30) {
// //     const months = Math.floor(days / 30);
// //     return `${months} month${months > 1 ? "s" : ""}`;
// //   } else if (days >= 7) {
// //     const weeks = Math.floor(days / 7);
// //     return `${weeks} week${weeks > 1 ? "s" : ""}`;
// //   } else {
// //     return `${days} day${days > 1 ? "s" : ""}`;
// //   }
// // }

// export function getDateDifferenceStatus(dueDate, completedDate = null) {
//   const due = dayjs(dueDate);
//   const referenceDate = completedDate ? dayjs(completedDate) : dayjs();

//   // Calculate difference in days
//   const daysDiff = referenceDate.diff(due, "day");

//   // Determine the output text based on days difference
//   let outputText;

//   // For completed tasks
//   if (completedDate) {
//     if (daysDiff < 0) {
//       const absDiff = Math.abs(daysDiff);
//       outputText = `Completed before ${formatTimeDiff(absDiff)}`;
//     } else if (daysDiff === 0) {
//       outputText = "Completed on the same day";
//     } else {
//       outputText = `Completed after ${formatTimeDiff(daysDiff)}`;
//     }
//   } else {
//     // For incomplete tasks
//     if (daysDiff < 0) {
//       outputText = `Time remaining: ${formatTimeDiff(Math.abs(daysDiff))}`;
//     } else {
//       outputText = `Missed by ${formatTimeDiff(daysDiff)} ago.`;
//     }
//   }

//   // Determine the color class based on rules
//   const colorClass =
//     (!completedDate && daysDiff >= 0) || (completedDate && daysDiff > 0)
//       ? "text-red-500"
//       : "text-green-500";

//   return (
//     <span className={`${colorClass} text-xs font-semibold`}>{outputText}</span>
//   );
// }

// // Helper function to format time differences
// function formatTimeDiff(days) {
//   if (days >= 365) {
//     const years = Math.floor(days / 365);
//     return `${years} year${years > 1 ? "s" : ""}`;
//   } else if (days >= 30) {
//     const months = Math.floor(days / 30);
//     return `${months} month${months > 1 ? "s" : ""}`;
//   } else if (days >= 7) {
//     const weeks = Math.floor(days / 7);
//     return `${weeks} week${weeks > 1 ? "s" : ""}`;
//   } else {
//     return `${days} day${days > 1 ? "s" : ""}`;
//   }
// }

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isToday);
dayjs.extend(isYesterday);

// Constants
const DEFAULT_TIMEZONE = "Asia/Kolkata";
const DEFAULT_FORMAT = "MMM DD, YYYY";

// Normalize Date for Safari Compatibility
function parseDate(dateString) {
  return dayjs.utc(dateString).tz(DEFAULT_TIMEZONE);
}

// Date Formatter Function
export function dateFormatter(
  dateString,
  options = { format: DEFAULT_FORMAT, includeTimeForToday: true }
) {
  console.log(dateString, "Input Date String");
  const date = parseDate(dateString);

  if (!date.isValid()) {
    console.error("Invalid Date:", dateString);
    return "Invalid Date";
  }

  const { format, includeTimeForToday } = options;

  if (date.isToday()) {
    return includeTimeForToday ? `Today ${date.format("HH:mm A")}` : `Today`;
  } else if (date.isYesterday()) {
    return includeTimeForToday
      ? `Yesterday ${date.format("HH:mm A")}`
      : "Yesterday";
  }

  return date.format(format);
}

// Group by Date Function
export const groupByDate = (items) => {
  if (!items || items.length === 0) return {};

  return items.reduce((acc, note) => {
    const noteDate = parseDate(note.createdAt);

    if (!noteDate.isValid()) {
      console.warn("Invalid date for item:", note);
      return acc;
    }

    let displayDate;
    if (noteDate.isToday()) {
      displayDate = "Today";
    } else if (noteDate.isYesterday()) {
      displayDate = "Yesterday";
    } else {
      displayDate = noteDate.format(DEFAULT_FORMAT);
    }

    if (!acc[displayDate]) {
      acc[displayDate] = [];
    }
    acc[displayDate].push(note);
    return acc;
  }, {});
};

// Get Date Difference Status Function
export function getDateDifferenceStatus(dueDate, completedDate = null) {
  const due = parseDate(dueDate);
  const referenceDate = completedDate ? parseDate(completedDate) : dayjs();

  if (!due.isValid() || (completedDate && !referenceDate.isValid())) {
    console.error("Invalid Date(s):", { dueDate, completedDate });
    return (
      <span className="text-red-500 text-xs font-semibold">Invalid Date</span>
    );
  }

  const daysDiff = referenceDate.diff(due, "day");
  let outputText;

  if (completedDate) {
    if (daysDiff < 0) {
      outputText = `Completed before ${formatTimeDiff(Math.abs(daysDiff))}`;
    } else if (daysDiff === 0) {
      outputText = "Completed on the same day";
    } else {
      outputText = `Completed after ${formatTimeDiff(daysDiff)}`;
    }
  } else {
    if (daysDiff < 0) {
      outputText = `Time remaining: ${formatTimeDiff(Math.abs(daysDiff))}`;
    } else {
      outputText = `Missed by ${formatTimeDiff(daysDiff)} ago.`;
    }
  }

  const colorClass =
    (!completedDate && daysDiff >= 0) || (completedDate && daysDiff > 0)
      ? "text-red-500"
      : "text-green-500";

  return (
    <span className={`${colorClass} text-xs font-semibold`}>{outputText}</span>
  );
}

// Helper Function to Format Time Differences
function formatTimeDiff(days) {
  if (days >= 365) {
    const years = Math.floor(days / 365);
    return `${years} year${years > 1 ? "s" : ""}`;
  } else if (days >= 30) {
    const months = Math.floor(days / 30);
    return `${months} month${months > 1 ? "s" : ""}`;
  } else if (days >= 7) {
    const weeks = Math.floor(days / 7);
    return `${weeks} week${weeks > 1 ? "s" : ""}`;
  } else {
    return `${days} day${days > 1 ? "s" : ""}`;
  }
}
